import React from 'react';
import Dashboard from './dashboard';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';

const App = () => {
  return (
    <div>
      {/* Content */}
      <div className='website-container'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Analytics />
      <SpeedInsights />
    </div>
  );
};

export default App;
