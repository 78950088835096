import { track } from '@vercel/analytics';

const TrackClick = (clickType, params) => {

        if (clickType === 'List'){
            return track('List', { name: params.className })
        } else if (clickType === 'Map'){
            console.log(params)
            return track('Map', { name: params.className })
        } else if (clickType === 'Feedback'){
            return track('Feedback')
        } else if (clickType === 'Submit a class'){
            return track('Submit a class')
        } else if (clickType === 'Logo'){
            return track('Logo')
        } else {
            return track('Other')
        }
}

export default TrackClick;