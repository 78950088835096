import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
//import { createBrowserHistory } from 'history';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-search/dist/leaflet-search.min.css';
import 'leaflet-geosearch/dist/geosearch.css';
import './styles/dashboard.css';
import logo from './images/cropped_logo.jpg';
import markerIcon from './images/marker_blue.png';
import dottedMarkerIcon from './images/marker_dotted.png';
import TrackClick from './components/TrackClick';

var venuesPath = 'https://bookmytrial.com/api/venues'

// Use this one for local testing
//var venuesPath = 'http://localhost:3000/venues';


function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '').replace(/\.(jpg|jpeg|png|gif|bmp|svg)$/i, '')] = r(item); });
  return images;
}
const images = importAll(require.context('./images', false, /\.(jpg|jpeg|png|gif|bmp|svg)$/));


const Dashboard = () => {
  const [originalVenues, setOriginalVenues] = useState([]);
  const [venues, setVenues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [baseMap] = useState({
    center: [37.782972, -122.430297],
    zoom: 13,
  });
  const [showActivitiesFilter, setShowActivitiesFilter] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState({
    dance: false,
    fitness: false,
    other: false,
  });
  const [searchInput, setSearchInput] = useState('');
  const [searchCenter, setSearchCenter] = useState(null); 
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [showMap, setShowMap] = useState(true);
  const mapRef = useRef(null);

  const rowHeight = 100;
  //const history = createBrowserHistory();

  const mapboxAccessToken = 'pk.eyJ1IjoicmFodWxhbWxla2FyIiwiYSI6ImNscnpxODBucDIyc2gyanAxZGJlbTRuZ3YifQ.BOLjFb-a1AE7je0wEgikBg';

  useEffect(() => {
    const map = mapRef.current;

    if (map) {
      // Define your initial map settings
      map.setView([37.773972, -122.531297],11);

      // Add a tile layer (you can use any tile provider)
      L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`, {
        attribution: '&copy; <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> contributors',
        id: 'mapbox/streets-v11', // Change to 'mapbox/streets-simple-v10'
      }).addTo(map);
      
    }
  }, []);
  useEffect(() => {
    if (mapRef.current) {
      if (searchCenter) {
        mapRef.current.setView(searchCenter, baseMap.zoom);
      } 
    }
  }, [searchCenter, venues, baseMap.zoom]);

  useEffect(() => {
    axios
      .get(venuesPath)
      .then((response) => {
        setVenues(response.data);
        setOriginalVenues(response.data);
        setIsLoading(false);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  console.log('originalVenues', originalVenues);

  useEffect(() => {
    // Apply filter based on selected activities
    const filteredVenues = originalVenues.filter((venue) => {
      if (selectedActivities.dance && !venue.dance) return false;
      if (selectedActivities.fitness && !venue.fitness) return false;
      if (selectedActivities.other && !venue.other) return false;
      return true;
    });
    
    // Set the filtered venues
    setVenues(filteredVenues);
  }, [selectedActivities, originalVenues]);

  useEffect(() => {
    const filteredVenues = originalVenues.filter((venue) => {
      const isActivityMatch = !selectedActivity || venue[selectedActivity];
      const isSearchMatch = venue.className.toLowerCase().includes(searchInput.toLowerCase());
      
      return isActivityMatch && isSearchMatch;
    });
    
    setVenues(filteredVenues);
  }, [selectedActivity, searchInput, originalVenues]);

  const columns = [
    {
      field: 'image',
      headerName: 'Visit Site',
      width: 120,
      renderCell: (params) => (
       <Link to={params.row.classTrialURL} target="_blank" onClick={() => TrackClick('List', params.row)} rel="noopener noreferrer">
            <img
              src={images[params.row.imgType]}
              alt={images[params.row.className]}
              style={{ width: '100%', height: 'auto', borderRadius: '5px' }}
            />
        </Link>
        
      ),
      disableColumnMenu: true,
      disableColumnHeaderSorting: true,
      headerClassName: 'disable-hover-effect',
    },
    {
      field: 'combinedInfo',
      flex: 1,
      headerName: "Venue",
      renderCell: (params) => (
        <div className='venue-info'> 
          <a className='venue-name' href={params.row.classTrialURL} target="_blank" rel="noopener noreferrer" 
            onClick={() => TrackClick('List', params.row)}
              //() => {track('List', {index:params.row.rowIndex, name: params.row.name})}}
          >
            <strong>{params.row.className}</strong>
          </a>
          <br />
          <div style={{ color: 'black', fontSize: 'small' }}>
            Trial: {`${params.row.classDuration}`}
          </div>
          <div style={{ color: 'gray', fontSize: '4' }}>
            {` ${params.row.address}`}
          </div>
        </div>
      ),
      disableColumnMenu: true,
      disableColumnHeaderSorting: true,
      headerClassName: 'disable-hover-effect',
    },
    {
      field: 'classInfo',
      headerName: "Details",
      renderCell: (params) => (
        <div className='class-details' style={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>
        {params.row.classInfo}
      </div>
      ),
      disableColumnMenu: true,
      disableColumnHeaderSorting: true,
      headerClassName: 'disable-hover-effect class-details',
    }
  ];

  const validVenues = venues.filter((venue) => venue.latitude && venue.longitude);

  const customIcon = new L.Icon({
    iconUrl: markerIcon,
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, -32],
  });

  const hoveredIcon = new L.Icon({
    iconUrl: dottedMarkerIcon,
    iconSize: [36, 36],
    iconAnchor: [18, 36],
    popupAnchor: [0, -30],
  });

  const markers = validVenues.map((venue, index) => (
    <Marker
      key={venue._id}
      position={[venue.latitude, venue.longitude]}
      icon={index === hoveredRowIndex ? hoveredIcon : customIcon}
    >
      <Popup>
        <div>
          <Link to={venue.classTrialURL} target="_blank" rel="noopener noreferrer" onClick={() => TrackClick('Map', venue)}>
            <img
              src={images[venue.imgType]}
              alt={images[venue.className]}
              style={{ width: '100%', height: 'auto' }}
            />
          </Link>
          <a className='venue-name' href={venue.classTrialURL} target="_blank" rel="noopener noreferrer"
            onClick={() => TrackClick('Map', venue)}>
            <strong>{venue.className}</strong>
          </a>
          <br />
          <div style={{ color: 'red', fontSize: '8' }}>
            Trial: {`${venue.classDuration}`}
          </div>
          <div style={{ color: 'gray', fontSize: '6' }}>
            {` ${venue.address}`}
          </div>
        </div>
      </Popup>
    </Marker>
  ));

  const handleRowHover = (index) => {
    console.log('Hovered Index:', index);
    setHoveredRowIndex(index);
  };
  

  const handleActivitiesFilterChange = (activity) => {
    setSelectedActivities((prevActivities) => ({
      ...prevActivities,
      [activity]: !prevActivities[activity],
    }));
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // const handleLocationSearch = async (event) => {
  //   const locationSearchValue = event.target.value;

  //   // Filter venues based on the location search
  //   const filteredVenues = originalVenues.filter((venue) => {
  //     const locationString = `${venue.city}, ${venue.county}, ${venue.state}`;
  //     return locationString.toLowerCase().includes(locationSearchValue.toLowerCase());
  //   });
    
  //   // Set the filtered venues
  //   setVenues(filteredVenues);

  //   // Set the search center to the last latitude and longitude
  //   if (filteredVenues.length > 0) {
  //     const lastVenue = filteredVenues[filteredVenues.length - 1];
  //     setSearchCenter([parseFloat(lastVenue.latitude), parseFloat(lastVenue.longitude)]);
  //   }
  //   else {
  //     const url = "https://api.mapbox.com/geocoding/v5/mapbox.places/" + encodeURIComponent(locationSearchValue) +
  //     ".json?access_token=" + mapboxAccessToken;
      
  //     const res = await axios.get(url);
  //     const lastVenue = res.data.features[0];
  //     setSearchCenter([parseFloat(lastVenue.geometry.coordinates[1]), parseFloat(lastVenue.geometry.coordinates[0])]);
  //   }
  // };

  const handleClearFilters = () => {
    setSelectedActivities({
      dance: false,
      fitness: false,
      other: false,
    });
    setSelectedActivity(null);
    setSearchInput('');
    setVenues(originalVenues);
  };

  const handleClick = () => {
    setShowMap(!showMap); // Toggle the boolean state
  };

  return (
      <div>
        {/* Header */}
        <header className="app-header">
          <a href = "https://www.bookmytrial.com/">
            <img src={logo} alt="Logo" className="logo" />
          </a>
          {/* Search bar */}
          <div className="search-bar">
            <div className="activity-search">
              <span className="search-icon">
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M5.05 10.65a5.67 5.67 0 1 1 11.341 0 5.67 5.67 0 0 1-11.34 0zm5.67-7.42a7.42 7.42 0 1 0 4.5 13.321c.042.09.1.174.175.248l2.762 2.763a.875.875 0 0 0 1.238-1.238l-2.763-2.762a.876.876 0 0 0-.214-.157A7.42 7.42 0 0 0 10.72 3.23z" fill="currentColor"></path>
                </svg>
              </span>
              <input
                type="text"
                className="search-input-box"
                placeholder="Yoga, fitness, dance..."
                value={searchInput}
                onChange={handleSearchInputChange}
              />
              {searchInput && (
                <span className="clear-search" onClick={handleClearFilters}>
                  x
                </span>
              )}
            </div>

            {/* <div className="location-search">
              <span className="search-icon">
                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M18.232 10.078c0 3.198-3.979 7.41-5.518 8.92a.848.848 0 0 1-1.196 0C9.978 17.488 6 13.276 6 10.078 6 6.721 8.738 4 12.116 4c3.377 0 6.116 2.721 6.116 6.078zm-3.892-.145c0 1.22-.996 2.21-2.224 2.21a2.217 2.217 0 0 1-2.224-2.21c0-1.22.996-2.21 2.224-2.21 1.228 0 2.224.99 2.224 2.21z" fill="currentColor"></path>
                </svg>
              </span>
              <input
                type="text"
                placeholder="City, neighborhood"
                onChange={handleLocationSearch}
              />

            </div> */}
          </div> 
          <div className="toggle-button-container">
            <button onClick={handleClick} className="toggle-button">
              {showMap ? 'List' : 'Map'}
            </button>
          </div>
          <div className="header-buttons">
            <a href="https://forms.office.com/r/3TN6Kd7Kxv" target="_blank" rel="noreferrer" className="header-button" onClick={() => TrackClick('Feedback')}>Feedback</a>
            <a href="https://forms.office.com/r/U88WgK9C5p" target="_blank" rel="noreferrer" className="header-button" onClick={() => TrackClick('Submit a class')}>Submit a class</a>
          </div>

        </header>
        {/* Filters Container */}
        <div className="filters-container">
          {/* Vertical Filters */}
          <div className="vertical-filters">
            {/* Activities Button */}
            {/* <button
              className="filter-button"
              onClick={() => setShowActivitiesFilter(!showActivitiesFilter)}
            >
              Activities
            </button> */}
          </div>
        </div>
        {/* Expandable Activities Filter */}
        {showActivitiesFilter && (
          <div className="activities-filter">
            <div className="activity-options">
              <label>
                <input
                  type="checkbox"
                  checked={selectedActivities.dance}
                  onChange={() => handleActivitiesFilterChange('dance')}
                />
                <span>Dance</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={selectedActivities.fitness}
                  onChange={() => handleActivitiesFilterChange('fitness')}
                />
                <span>Fitness</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={selectedActivities.other}
                  onChange={() => handleActivitiesFilterChange('other')}
                />
                <span>Other</span>
              </label>
            </div>

            {/* Filter Controls */}
            <div className="filter-controls">
              <button className="filter-clear-button" onClick={handleClearFilters}>
                <span>Clear Filters</span>
              </button>
            </div>
          </div>
        )}

        {/* Dashboard Container */}
        <div className="dashboard-container">
          <div className={`grid ${showMap ? '' : 'visible'}`} >
            <DataGrid
              rows={venues}
              columns={columns}
              pageSize={5}
              getRowId={(row) => row._id}
              rowHeight={rowHeight}
              onCellHover={(params) => handleRowHover(params.rowIndex)}
              disableColumnMenu
              className='data-grid-row'
              sortModel={[]}
              pageSizeOptions={ [25]}
              columnVisibilityModel={{classInfo: false}}
              loading={isLoading}
              slots={{
                columnHeaders: () => null,
              }}
              hideFooter
            />
          </div>
          <div className={`map ${showMap ? 'visible' : ''}`}>
            <MapContainer
              ref={mapRef}
              style={{ height: '100%' }}
              center={baseMap.center}
              zoom={baseMap.zoom}
            > 
              <TileLayer
                url={`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`}
                attribution='&copy; <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> contributors'
                id='mapbox/streets-v11' // Change to 'mapbox/streets-simple-v10' if needed
              />
              {markers}
            </MapContainer>
          </div>
        </div>
      </div>
  );
};

export default Dashboard;